import React from "react"
import { FaHome, FaCheck, FaRegSun, FaTicketAlt } from 'react-icons/fa'
import { BsFillNutFill } from 'react-icons/bs'
import { HiTemplate } from "react-icons/hi";
import { MdOutlineSecurity } from "react-icons/md";
import {
  COORDINADOR_REGION, DIRECTOR_OPERACIONES, MASTER_ROL, SUPERVISOR_ROL, ANALISTA_ADMIN, LIDEROP_ROL, GERENTE,
  CLIENTE,
  MONITOREO,
  ADMINISTRATIVO,
  CONTABILIDAD_SUPLY_LOGISTICS,
  OPERADOR
} from "../utility/roles";
const user = JSON.parse(sessionStorage.getItem('user'))




const Supervisor = [
  {
    id: "Operaciones Master",
    title: "Operaciones Master",
    type: "item",
    icon: <HiTemplate size={17} />,
    permissions: ["admin", "editor"],
    navLink: "/Operaciones",
  }
];

const Master = [
  {
    id: "Operaciones Master",
    title: "Operaciones Master",
    type: "item",
    icon: <HiTemplate size={17} />,
    permissions: ["admin", "editor"],
    navLink: "/Operaciones",
  },
  {
    id: "Inasistencias",
    title: "Inasistencias",
    type: "item",
    icon: <HiTemplate size={17} />,
    permissions: ["admin", "editor"],
    navLink: "/inasistencias",
  },
  {
    id: "Estratificadas",
    title: "Estratificadas",
    type: "item",
    icon: <HiTemplate size={17} />,
    permissions: ["admin", "editor"],
    navLink: "/Estratificadas",
  },
  {
    id: "Mantenimiento",
    title: "Mantenimiento",
    type: "collapse",
    icon: <BsFillNutFill size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "Acciones",
        title: "Acciones",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/acciones",
      },
      {
        id: "registro-powerbi",
        title: "registro-powerbi",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/registro-powerbi",
      },

      {
        id: "Clientes",
        title: "Clientes",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/clientes",
      },
      {
        id: "Contenido",
        title: "Contenido",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/contenido",
      },
      {
        id: "Dependencia",
        title: "Dependencia",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/dependencia",
      },
      {
        id: "Empresas",
        title: "Empresas",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/empresas",
      },
      {
        id: "empresa-cliente",
        title: "empresa-cliente",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/empresa-cliente",
      },
      {
        id: "Estados",
        title: "Estados",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/estados",
      },
      {
        id: "Fases",
        title: "Fases",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/fases",
      },
      {
        id: "Justificaciones",
        title: "Justificaciones",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/justificaciones",
      },
      {
        id: "Planta-Fase",
        title: "Planta-Fase",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/planta-fase",
      },
      {
        id: "Plantas",
        title: "Plantas",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/plantas",
      },
      {
        id: "Planta-Dependencia",
        title: "Planta-Dependencia",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/planta-dependencia",
      },
      {
        id: "regiones",
        title: "regiones",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/regiones",
      },
      {
        id: "tiposGastos",
        title: "tipos de gastos",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/tiposGastos",
      },
      {
        id: "tiposOperaciones",
        title: "tipos de operaciones",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/tiposOperaciones",
      },
      {
        id: "trasnportistas",
        title: "transportistas",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/transportistas",
      },
      {
        id: "tipoTransporte",
        title: "tipoTransporte",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/tipoTransporte",
      },
      {
        id: "unidades",
        title: "unidades",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/unidades",
      },
    ]
  },
  {
    id: "Seguridad",
    title: "Seguridad",
    type: "collapse",
    icon: <MdOutlineSecurity size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "Cargos",
        title: "Cargos",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/seguridad/cargos",
      },
      {
        id: "Modulos",
        title: "Modulos",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/seguridad/modulos",
      },
      {
        id: "Roles",
        title: "Roles",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/seguridad/roles",
      },
      {
        id: "Rol-Modulos",
        title: "Rol-Modulos",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/seguridad/rol-modulos",
      },
      {
        id: "Tipo de Identificación",
        title: "Tipo de Identificación",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/seguridad/tipoIdentificacion",
      },
      {
        id: "usuarios",
        title: "usuarios",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/seguridad/usuarios",
      },
    ]
  }

]
const DirectorOperaciones = [
  {
    id: "Operaciones Master",
    title: "Operaciones Master",
    type: "item",
    icon: <HiTemplate size={17} />,
    permissions: ["admin", "editor"],
    navLink: "/Operaciones",
  },
  {
    id: "Estratificadas",
    title: "Estratificadas",
    type: "item",
    icon: <HiTemplate size={17} />,
    permissions: ["admin", "editor"],
    navLink: "/Estratificadas",
  },
  {
    id: "PowerBi",
    title: "PowerBi",
    type: "collapse",
    icon: <BsFillNutFill size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "Detalle Powerbi",
        title: "Detalle Powerbi",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/detalle-powerbi",
      },
      {
        id: "registro-powerbi",
        title: "registro-powerbi",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/registro-powerbi",
      },
    ]
  }
]
const CoordinadorRegion = [
  {
    id: "Operaciones Master",
    title: "Operaciones Master",
    type: "item",
    icon: <HiTemplate size={17} />,
    permissions: ["admin", "editor"],
    navLink: "/Operaciones",
  },
  {
    id: "Estratificadas",
    title: "Estratificadas",
    type: "item",
    icon: <HiTemplate size={17} />,
    permissions: ["admin", "editor"],
    navLink: "/Estratificadas",
  },
  {
    id: "PowerBi",
    title: "PowerBi",
    type: "collapse",
    icon: <BsFillNutFill size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "Detalle Powerbi",
        title: "Detalle Powerbi",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/detalle-powerbi",
      },
      {
        id: "registro-powerbi",
        title: "registro-powerbi",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/registro-powerbi",
      },
    ]
  }
]
const AnalistaAdmin = [
  {
    id: "Operaciones Master",
    title: "Operaciones Master",
    type: "item",
    icon: <HiTemplate size={17} />,
    permissions: ["admin", "editor"],
    navLink: "/Operaciones",
  },
  {
    id: "Estratificadas",
    title: "Estratificadas",
    type: "item",
    icon: <HiTemplate size={17} />,
    permissions: ["admin", "editor"],
    navLink: "/Estratificadas",
  },

  ,
  {
    id: "PowerBi",
    title: "PowerBi",
    type: "collapse",
    icon: <BsFillNutFill size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "Detalle Powerbi",
        title: "Detalle Powerbi",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/detalle-powerbi",
      },
      {
        id: "registro-powerbi",
        title: "registro-powerbi",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/registro-powerbi",
      },
    ]
  }
]
const Cliente = [
  {
    id: "Operaciones Master",
    title: "Operaciones Master",
    type: "item",
    icon: <HiTemplate size={17} />,
    permissions: ["admin", "editor"],
    navLink: "/Operaciones",
  },
  {
    id: "PowerBi",
    title: "PowerBi",
    type: "collapse",
    icon: <BsFillNutFill size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "Detalle Powerbi",
        title: "Detalle Powerbi",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/detalle-powerbi",
      },
      {
        id: "registro-powerbi",
        title: "registro-powerbi",
        type: "item",
        icon: <HiTemplate size={17} />,
        permissions: ["admin", "editor"],
        navLink: "/registro-powerbi",
      },
    ]
  }

];


const navigationConfig =

  user?.profile === SUPERVISOR_ROL ? Supervisor :
    user?.profile === MASTER_ROL ? Master :
      user?.profile === DIRECTOR_OPERACIONES ? DirectorOperaciones :
        user?.profile === COORDINADOR_REGION ? CoordinadorRegion :
          user?.profile === ANALISTA_ADMIN ? AnalistaAdmin :
            user?.profile === LIDEROP_ROL ? Supervisor :
              user?.profile === CLIENTE ? Cliente :
                user?.profile === MONITOREO ? Supervisor :
                  user?.profile === ADMINISTRATIVO ? Supervisor :
                    user?.profile === CONTABILIDAD_SUPLY_LOGISTICS ? Supervisor :
                      user?.profile === OPERADOR ? Supervisor :
                        []

export default navigationConfig


/* agregar el tipo-transporte a las rutas y arreglas el error de plantas y termino. */